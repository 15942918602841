import PropTypes from 'prop-types';
import React, {Component, Fragment} from 'react';
import {Translate, withLocalize} from 'react-localize-redux';
import { connect } from 'react-redux';
import {Alert, Button, Form, FormFeedback, FormGroup, Input, Label} from 'reactstrap';

import { isEmptyString } from '../../../../../utils/string-utils';
import {STATUS_OFF} from "../../../../../constants";
import {lostStreamModePropTypes, multiViewPropTypes} from "../../../../../utils/models-prop-types";
import {Formik} from "formik";
import withLogger from "../../../../hoc/with-logger";
import { getAvailableLostStreamModes, getAvailableMosaicOutputStandards, getAvailableMosaicAudioChannelModes } from '../../../../../misc/capabilities.selectors';
import HelpLayout from '../../../../common/help-layout';
import CustomPatternForm from '../../../settings/custom-pattern/custom-pattern-form';
import {SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX} from '../../../../../constants'

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  multiView: multiViewPropTypes.isRequired,
  config: PropTypes.shape({
    name: PropTypes.string.isRequired,
    outputStandard: PropTypes.string.isRequired,
    forceAspectRatio: PropTypes.bool.isRequired,
    lostStreamMode: PropTypes.number.isRequired,
    lostPatternMode: PropTypes.number.isRequired,
    lostStreamTimeout: PropTypes.number.isRequired,
    showOverlayInfo: PropTypes.bool
  }),
  lostStreamModes: PropTypes.arrayOf(lostStreamModePropTypes).isRequired,
  outputStandards: PropTypes.arrayOf(PropTypes.string).isRequired,
  audioChannelModes: PropTypes.arrayOf(PropTypes.string).isRequired
};

const CUSTOM_PATTERN_VALUE = 3;

class GeneralForm extends Component {

  constructor(props){
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCustomPattern = this.handleCustomPattern.bind(this);

    this.state = {
      customPatternFormVisible: false
    }
  }

  componentDidMount(){
    if(this.props.config.lostStreamMode === CUSTOM_PATTERN_VALUE || this.props.config.lostPatternMode === CUSTOM_PATTERN_VALUE){
      this.handleCustomPattern(true);
    }
  }

  handleCustomPattern(display){
    this.setState({
      customPatternFormVisible: display
    });
  }


  handleSubmit(values, { resetForm }){
    this.props.awlogger.info('Values: ', values);
    const data = {
      ...values,
      lostStreamMode: parseInt(values.lostStreamMode),
      lostPatternMode: parseInt(values.lostPatternMode)
    };
    this.props.onSubmit(data);
    resetForm(values);
  };

  handleValidation = (values) => {
    const errors = {};
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    const offset = parseInt(values.lostStreamTimeout);
    if(isNaN(offset)){
      errors.lostStreamTimeout = 'genericLabel.REQUIRED_FIELD.text';
    }
    return errors;
  };

  render() {
    const props = this.props;
    const {translate, multiView, outputStandards, audioChannelModes, lostStreamModes} = props;
    return (
      <Formik initialValues={{
                name: props.config.name,
                audioChannelMode: props.config.audioChannelMode,
                outputStandard: props.config.outputStandard,
                forceAspectRatio: props.config.forceAspectRatio,
                lostPatternMode: `${props.config.lostPatternMode}`, // We transform to string here so that 'dirty' prop works
                lostStreamMode: `${props.config.lostStreamMode}`, // We transform to string here so that 'dirty' prop works
                lostStreamTimeout: props.config.lostStreamTimeout,
                showOverlayInfo: props.config.showOverlayInfo ? props.config.showOverlayInfo : false,
              }}
              validate={this.handleValidation}
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={this.handleSubmit}>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit
            /* and other goodies */
          }) => (
          <div>
            <Form className="output-settings-form"
                  onSubmit={handleSubmit}>

              <HelpLayout filename="ref_multiview_settings"
                form={<Fragment>

                  {multiView.status !== STATUS_OFF &&
                  <Alert color="warning">
                    <Translate id="genericLabel.MODIFYING_CONFIGURATION_INGEST_PROFILE_MAY_DISCONNECT_DEVICE.text"/>
                  </Alert>
                  }
                  <FormGroup>
                    <Label for="name">
                      <Translate id="genericLabel.NAME.text"/>
                    </Label>
                    <Input type="text"
                           required
                           name="name"
                           id="multiviewSetting_modal_name"
                           invalid={errors.name !== undefined}
                           placeholder={translate("genericLabel.NAME.text")}
                           value={values.name}
                           onBlur={handleBlur}
                           onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.name}/>
                    </FormFeedback>
                    {/*<div className="indicator">*/}
                    {/*<Translate id={`genericLabel.NAME_DISPLAYED_ON_PREVIEW_AND_OUTPUT.text`}/>*/}
                    {/*</div>*/}
                  </FormGroup>


                  {/*<FormGroup check>*/}
                    {/*<Label check>*/}
                      {/*<Input type="checkbox"*/}
                             {/*name="showOverlayInfo"*/}
                             {/*onChange={handleChange}*/}
                             {/*checked={values.showOverlayInfo}/>{' '}*/}
                      {/*<Translate id="genericLabel.SHOW_OVERLAY_INFORMATION.text"/>*/}
                    {/*</Label>*/}
                  {/*</FormGroup>*/}

                  <FormGroup>
                    <Label for="outputStandard">
                      <Translate id="genericLabel.OUTPUT_STANDARD.text"/>
                    </Label>
                    <Input type="select"
                           name="outputStandard"
                           id="multiviewSetting_modal_outputStandard"
                           onChange={handleChange}
                           value={values.outputStandard}>
                      {outputStandards.map(standard => {
                        return <option key={standard} value={standard}>{standard}</option>
                      })}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="audioChannelMode">
                      <Translate id="genericLabel.AUDIO_CHANNEL_MODE.text"/>
                    </Label>
                    <Input type="select"
                           name="audioChannelMode"
                           id="multiviewSetting_modal_audioChannelMode"
                           onChange={handleChange}
                           value={values.audioChannelMode}>
                      {audioChannelModes.map(standard => {
                        return <option key={standard} value={standard}>{standard}</option>
                      })}
                    </Input>
                  </FormGroup>

                  <FormGroup check>
                    <Label check>
                      <Input type="checkbox"
                             name="forceAspectRatio"
                            id="multiviewSetting_modal_forceAspectRatio"
                             onChange={handleChange}
                             checked={values.forceAspectRatio}/>{' '}
                      <Translate id="genericLabel.FORCE_ASPECT_RATIO.text"/>
                    </Label>
                  </FormGroup>

                  <FormGroup>
                    <Label for="lostStreamMode">
                      <Translate id="genericLabel.DEFAULT_VIDEO_PATTERN.text"/>
                    </Label>
                    <Input type="select"
                           name="lostStreamMode"
                           id="multiviewSetting_modal_lostStreamMode"
                           onChange={(e) => {
                             handleChange(e);
                             if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostPatternMode) !== CUSTOM_PATTERN_VALUE) {
                               this.handleCustomPattern(false);
                             }
                             else {
                               this.handleCustomPattern(true);
                             }
                           }}
                           value={values.lostStreamMode}>
                      {lostStreamModes.map(dest => {
                        return <option key={dest.value}
                                       value={dest.value} 
                                       id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                      })}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="lostPatternMode">
                      <Translate id="genericLabel.POLICY_VIDEO_PATTERN.text"/>
                    </Label>
                    <Input type="select"
                           name="lostPatternMode"
                           id="multiviewSetting_modal_lostPatternMode"
                           onBlur={handleBlur}
                           onChange={(e) => {
                             handleChange(e);
                             if (parseInt(e.target.value) !== CUSTOM_PATTERN_VALUE && parseInt(values.lostStreamMode) !== CUSTOM_PATTERN_VALUE) {
                              this.handleCustomPattern(false);
                             }
                             else {
                              this.handleCustomPattern(true);
                             }
                           }}
                           value={values.lostPatternMode}>
                      {lostStreamModes.map(dest => {
                        return <option key={dest.value}
                                       value={dest.value}
                                       id={translate(`lostStreamMode.${dest.name}.text`)}>{translate(`lostStreamMode.${dest.name}.text`)}</option>
                      })}
                    </Input>
                  </FormGroup>

                  <FormGroup>
                    <Label for="lostStreamTimeout">
                      <Translate id="genericLabel.TIMEOUT.text"/>
                    </Label>
                    <Input type="number"
                           required
                           name="lostStreamTimeout"
                           id="multiviewSetting_modal_lostStreamTimeout"
                           invalid={errors.lostStreamTimeout !== undefined}
                           placeholder={translate("genericLabel.TIMEOUT.text")}
                           value={values.lostStreamTimeout}
                           onBlur={handleBlur}
                           onChange={handleChange}/>
                    <FormFeedback>
                      <Translate id={errors.lostStreamTimeout}/>
                    </FormFeedback>
                    <div className="indicator">
                      <Translate id="genericLabel.TIMEOUT_LOST_STREAM_MODE.text"/>
                    </div>
                  </FormGroup>

                  { this.state.customPatternFormVisible &&
                    <CustomPatternForm prefix={SETTINGS_MULTIVIEW_CUSTOM_PATTERN_PREFIX} id="0" timestamp={new Date().getTime()}/>
                  }
                </Fragment>
                }

                buttons={
                  <FormGroup className="buttons">
                    <Button id="multiviewSetting_modal_saveButton"
                            color="primary"
                            disabled={!dirty}
                            type="submit">
                      <Translate id="genericLabel.SAVE.text"/>
                    </Button>
                  </FormGroup>} />
            </Form>
          </div>
        )}
      </Formik>
    );
  }
}

GeneralForm.propTypes = propTypes;

const mapStateToProps = (state) => {
  return {
    config: state.config.mosaic,
    multiView: state.streamhub.multiView,
    lostStreamModes: getAvailableLostStreamModes(state),
    outputStandards: getAvailableMosaicOutputStandards(state),
    audioChannelModes: getAvailableMosaicAudioChannelModes(state)
  }
};

export default connect(mapStateToProps)(withLocalize(withLogger(GeneralForm)));