import {Formik} from "formik";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import {Translate, withLocalize} from "react-localize-redux";
import { connect } from 'react-redux';
import {Button, Form, FormFeedback, FormGroup, Input, Label, Alert} from "reactstrap";
import RestartStreamhubModal from "../../tools/system/restart-streamhub-modal";
import { isEmptyString} from "../../../../utils/string-utils";
import {videoCardCapabilitiesPropTypes} from "../../../../utils/models-prop-types";
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import Accordion from "../../../common/accordion";

import {VIDEO_CARD_TYPE_RIVERMAX } from "../../../../constants";
import { licenseModal } from "../../tools/system/system.tools.actions";
import LicenseModal from "./license-form";
import { uploadLicenseSMPTE2110 } from "../../../../misc/license.actions";

const propTypes = {
    config: PropTypes.shape({
        '4KMode': PropTypes.bool,
        forceFullFrameRate: false,
        nbSDIInput: PropTypes.number,
        sdiOrder: PropTypes.number,
        videoFrameRate: PropTypes.string,
        enableDHCP1: PropTypes.bool,
        enableDHCP2: PropTypes.bool,
      }).isRequired,
      videoFrameRates: PropTypes.arrayOf(PropTypes.string),
      videoCard: videoCardCapabilitiesPropTypes.isRequired,
      onSubmit: PropTypes.func.isRequired,
      smpteIps: PropTypes.arrayOf(PropTypes.string),
      callLicenseModal: PropTypes.func.isRequired,
      licenseModalOpened: PropTypes.bool.isRequired,
      uploading: PropTypes.bool,
      result: PropTypes.shape({
        res: PropTypes.number.isRequired,
        message: PropTypes.string,
      }),
      callUploadLicense: PropTypes.func.isRequired, // temp
      licenseStatus: PropTypes.bool.isRequired,
};

class VideoCardSMPTESettingsForm extends Component {

  constructor(props){
    super(props);

    this.handleConfirm = this.handleConfirm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleValidate = this.handleValidate.bind(this);
    this.state = {
      confirmModalOpened: false
    };
  }

  handleConfirm(value) {
    this.setState({
      confirmModalOpened: value
    });
  }

  handleSubmit(values, { resetForm }){
    if(values.enableDHCP1){
        values.ipaddr1=this.props.smpteIps[0];
        values.mask1='';
      }
      if(values.enableDHCP2){
        values.ipaddr2=this.props.smpteIps[1];
        values.mask2='';
      }
       values.ptpLogAnnounceInterval = parseInt(values.ptpLogAnnounceInterval, 10);
       values.ptpLogSyncInterval = parseInt(values.ptpLogSyncInterval, 10);
      this.props.onSubmit(this.props.videoCard.id, values);
      resetForm();
      this.handleConfirm(false) //temp
  }

  handleValidate(values){
    const errors = {};
    const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))$/;
    //mask Regex
    var maskRegex = /^(((255\.){3}(255|254|252|248|240|224|192|128|0+))|((255\.){2}(255|254|252|248|240|224|192|128|0+)\.0)|((255\.)(255|254|252|248|240|224|192|128|0+)(\.0+){2})|((255|254|252|248|240|224|192|128|0+)(\.0+){3}))$/;
      if(!values.enableDHCP1){
        // ipaddr1
        if(isEmptyString(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr1)) {
          errors.ipaddr1 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask1
        if(isEmptyString(values.mask1)) {
          errors.mask1 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask1)) {
          errors.mask1 = 'genericLabel.INVALID_FORMAT.text';
        }
        if(isEmptyString(values.defaultGateway)) {
            errors.defaultGateway = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!ipRegex.test(values.defaultGateway)) {
          errors.defaultGateway = 'genericLabel.INVALID_FORMAT.text';
        }
      }
      if(!values.enableDHCP2){
        // ipaddr2
        if(isEmptyString(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.REQUIRED_FIELD.text';
        } else if (!ipRegex.test(values.ipaddr2)) {
          errors.ipaddr2 = 'genericLabel.INVALID_FORMAT.text';
        }
        // mask2
        if(isEmptyString(values.mask2)) {
          errors.mask2 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!maskRegex.test(values.mask2)) {
          errors.mask2 = 'genericLabel.INVALID_FORMAT.text';
        }
        if(isEmptyString(values.defaultGateway2)) {
            errors.defaultGateway2 = 'genericLabel.REQUIRED_FIELD.text';
        }
        else if (!ipRegex.test(values.defaultGateway2)) {
          errors.defaultGateway2 = 'genericLabel.INVALID_FORMAT.text';
        }
      }
      if(values.ptpDomainNumber<0 ||  values.ptpDomainNumber>127){
        errors.ptpDomainNumber = 'genericLabel.INVALID_FORMAT.text';
      }
      if(values.ptpAnnounceReceiptTimeout<2 ||  values.ptpAnnounceReceiptTimeout>10){
        errors.ptpAnnounceReceiptTimeout = 'genericLabel.INVALID_FORMAT.text';
      }
    return errors;
  }

  render() {
    const { config, videoCard, smpteIps,licenseModalOpened, uploading, result, callUploadLicense, licenseStatus} = this.props;
    return (<div>
        {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX && !licenseStatus &&
              <Alert color="danger">
                Invalid or missing SMPTE2110 License, Please add one to continue
              </Alert>
              }
        {videoCard.sdiCardType === VIDEO_CARD_TYPE_RIVERMAX &&
        <Button id={"videoCard_"+videoCard.modelName+"_uploadLicense"}
                type="submit"//disabled={hasUserLevel || hasViewerLevel}
                color={!licenseStatus ? "primary" : "secondary"}
                onClick={() => this.props.callLicenseModal(true)}>
                    <AWIcon name="license"/>
                    <span className="text">Upload SMPTE 2110 License</span>
                  </Button>}
                  { licenseModalOpened &&
                  <LicenseModal onCancel={() => this.props.callLicenseModal(false)}
                                loading={uploading}
                                result={result}
                                onSubmit={callUploadLicense}/>
                  }
      <Formik initialValues={{
                '4KMode': config['4KMode'],
                sdiOrder: config.sdiOrder,
                videoFrameRate: config.videoFrameRate,
                forceFullFrameRate: config.forceFullFrameRate,
                enableDHCP1: config.enableDHCP1 ? config.enableDHCP1 : false,
                enableDHCP2: config.enableDHCP2 ? config.enableDHCP2 : false,
                ipaddr1: config.enableDHCP1 ? smpteIps[0] : (config.ipaddr1 ? config.ipaddr1 : '' ),
                ipaddr2: config.enableDHCP2 ? smpteIps[1] : (config.ipaddr2 ? config.ipaddr2 : '' ),
                mask1: config.mask1 ? config.mask1 : '',
                mask2: config.mask2 ? config.mask2 : '',
                ptpEnable: config.ptpEnable!==undefined ? config.ptpEnable : true,
                ptpDomainNumber: config.ptpDomainNumber!==undefined ? config.ptpDomainNumber : 127,
                ptpLogAnnounceInterval: config.ptpLogAnnounceInterval!==undefined ? config.ptpLogAnnounceInterval : 0,
                ptpAnnounceReceiptTimeout: config.ptpAnnounceReceiptTimeout!==undefined ? config.ptpAnnounceReceiptTimeout : 3,
                ptpLogSyncInterval: config.ptpLogSyncInterval!==undefined ? config.ptpLogSyncInterval : -3,
                ptpDelay_mechanism: config.ptpDelay_mechanism!==undefined ? config.ptpDelay_mechanism : "E2E",
                //nmosEnable: config.nmosEnable!==undefined ? config.nmosEnable : false,
                defaultGateway: config.defaultGateway ? config.defaultGateway : '',
                defaultGateway2: config.defaultGateway2 ? config.defaultGateway2 : '',
              }}
              validate={ this.handleValidate }
              validateOnBlur={false}
              validateOnChange={true}
              onSubmit={ this.handleSubmit }>
        {({
            values,
            errors,
            dirty,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            /* and other goodies */
          }) => {
          return (
            <Form onSubmit={handleSubmit}>
            { dirty &&
            <Alert color="warning">
              <Translate id="genericLabel.RESTART_STREAMHUB_TO_TAKE_CHANGES_INTO_ACCOUNT.text"/>
            </Alert>
            }
            { videoCard.has4K &&
            <FormGroup check>
              <Label check>
                <Input id={"videoCard_"+videoCard.modelName+"_enable4K"}
                       type="checkbox"
                       name="4KMode"
                       onChange={handleChange}
                       checked={values['4KMode']}/>{' '}
                <Translate id="genericLabel.4K_MODE.text"/>
              </Label>
            </FormGroup>
            }
              <div className="header">
                    <div className="title secondary">
                      Interface 1
                    </div>
                  </div>
            <FormGroup check>
            <Label check>
              <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP1"}
                     type="checkbox"
                     name="enableDHCP1"
                     onChange={handleChange}
                     disabled={!licenseStatus}
                     checked={values.enableDHCP1}/>{' '}
                     Enable DHCP
              {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
            </Label>
          </FormGroup>
                  <FormGroup>
                  <Label for="ipaddr1">
                    Ip adress
                  </Label>
                  <Input type="string"
                         name="ipaddr1"
                         id={"videoCard_"+videoCard.modelName+"_ipAddr1"}
                         disabled={values.enableDHCP1 || !licenseStatus}
                         invalid={ errors.ipaddr1 !== undefined }
                         value={values.ipaddr1}
                         onBlur={handleBlur}
                         onChange={handleChange}/>
                  <FormFeedback>
                    <Translate id={errors.ipaddr1}/>
                  </FormFeedback>
                </FormGroup>
                {values.enableDHCP1===false &&
             <> <FormGroup>
                <Label for="mask1">
                  Mask
                </Label>
                <Input type="string"
                       name="mask1"
                       id={"videoCard_"+videoCard.modelName+"_mask1"}
                       invalid={ errors.mask1 !== undefined }
                       value={values.mask1}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="defaultGateway">
                  Default Gateway
                </Label>
                <Input type="string"
                       name="defaultGateway"
                       id={"videoCard_"+videoCard.modelName+"_defaultGateway"}
                       disabled={values.enableDHCP1 || !licenseStatus}
                       invalid={ errors.defaultGateway !== undefined }
                       value={values.defaultGateway}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.defaultGateway}/>
                </FormFeedback>
              </FormGroup></>}
              <div className="header">
                  <div className="title secondary">
                    Interface 2
                  </div>
                </div>
          <FormGroup check>
          <Label check>
            <Input id={"videoCard_"+videoCard.modelName+"_enableDHCP2"}
                   type="checkbox"
                   name="enableDHCP2"
                   onChange={handleChange}
                   checked={values.enableDHCP2}
                   disabled={!licenseStatus}/>{' '}
                   Enable DHCP
            {/*<Translate id="genericLabel.DISPLAY_OUTPUT_NAME.text"/> fix me  */}
          </Label>
        </FormGroup>
                <FormGroup>
                <Label for="ipaddr2">
                  Ip adress
                </Label>
                <Input type="string"
                       name="ipaddr2"
                       id={"videoCard_"+videoCard.modelName+"_ipAddr2"}
                       disabled={values.enableDHCP2 || !licenseStatus}
                       invalid={ errors.ipaddr2 !== undefined }
                       value={values.ipaddr2}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ipaddr2}/>
                </FormFeedback>
              </FormGroup>
          
        {values.enableDHCP2===false && 
             <> <FormGroup>
                <Label for="mask2">
                  Mask
                </Label>
                <Input type="string"
                       name="mask2"
                       id={"videoCard_"+videoCard.modelName+"_mask2"}
                       invalid={ errors.mask2 !== undefined }
                       value={values.mask2}
                       onBlur={handleBlur}
                       onChange={handleChange}
                       disabled={!licenseStatus}/>
                <FormFeedback>
                  <Translate id={errors.mask1}/>
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="defaultGateway2">
                  Default Gateway
                </Label>
                <Input type="string"
                       name="defaultGateway2"
                       id={"videoCard_"+videoCard.modelName+"_defaultGateway2"}
                       disabled={values.enableDHCP2 || !licenseStatus}
                       invalid={ errors.defaultGateway2 !== undefined }
                       value={values.defaultGateway2}
                       onBlur={handleBlur}
                       onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.defaultGateway2}/>
                </FormFeedback>
              </FormGroup></>}
              <div className="header">
                  <div className="title secondary">
                    PTP
                  </div>
                </div>
                 { ((config.ptpEnable!==undefined && config.ptpEnable !==values.ptpEnable) || (config.ptpEnable===undefined && !values.ptpEnable )) &&
                    <Alert color="warning">
                      Reboot necessary for the change to take effect
                    </Alert>
                  }
                <FormGroup check>
                  <Label check>
                    <Input id={"videoCard_"+videoCard.modelName+"_ptpEnable"}
                          type="checkbox"
                          name="ptpEnable"
                          onChange={handleChange}
                          checked={values.ptpEnable}
                          disabled={!licenseStatus}/>{' '}
                          Enable PTP
                    </Label>
                </FormGroup>
                <FormGroup>
                  <Label for="ptpDomainNumber">
                     Domain Number
                  </Label>
                  <Input type="number"
                        name="ptpDomainNumber"
                        id={"videoCard_"+videoCard.modelName+"_ptpDomainNumber"}
                        min={0}
                        max={127}
                        default={0}
                        value={values.ptpDomainNumber}
                        disabled={!values.ptpEnable}
                        invalid={ errors.ptpDomainNumber !== undefined }
                        onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ptpDomainNumber}/>
                </FormFeedback>
                </FormGroup>
                <Accordion className="form-advanced-section"
                            renderTitle={() => (<Translate id="genericLabel.ADVANCED.text"/>)}>
                  <FormGroup>
                    <Label for="ptpLogAnnounceInterval">
                      Announce Interval
                    </Label>
                    <Input type="select"
                          name="ptpLogAnnounceInterval"
                          id={"videoCard_"+videoCard.modelName+"_ptpLogAnnounceInterval"}
                          value={values.ptpLogAnnounceInterval}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={-3} id="8Hz">0.125s 8Hz</option>
                      <option value={-2} id="4Hz">0.25s 4Hz</option>
                      <option value={-1} id="2Hz">0.5s 2Hz</option>
                      <option value={0} id="1Hz">1s 1Hz</option>
                      <option value={1} id="0.5Hz">2s 0.5Hz</option>
                    </Input>
                </FormGroup>
                  <FormGroup>
                    <Label for="ptpAnnounceReceiptTimeout">
                      Announce receipt timeout
                    </Label>
                    <Input type="number"
                          name="ptpAnnounceReceiptTimeout"
                          id={"videoCard_"+videoCard.modelName+"_ptpAnnounceReceiptTimeout"}
                          min={2}
                          max={10}
                          value={values.ptpAnnounceReceiptTimeout}
                          disabled={!values.ptpEnable}
                          invalid={ errors.ptpAnnounceReceiptTimeout !== undefined }
                          onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.ptpAnnounceReceiptTimeout}/>
                </FormFeedback>
                  </FormGroup>
                  <FormGroup>
                    <Label for="ptpLogSyncInterval">
                    Log sync interval
                    </Label>
                    <Input type="select"
                          name="ptpLogSyncInterval"
                          id={"videoCard_"+videoCard.modelName+"_ptpLogSyncInterval"}
                          value={values.ptpLogSyncInterval}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={0} id="1Hz">1s 1Hz</option>
                      <option value={-1} id="2Hz">0.5s 2Hz</option>
                      <option value={-2} id="4Hz">0.25s 4Hz</option>
                      <option value={-3} id="8Hz">0.125s 8Hz</option>
                      <option value={-4} id="16Hz">0.0625s 16Hz</option>
                      <option value={-5} id="32Hz">0.03125s 32Hz</option>
                      <option value={-6} id="64Hz">0.015625s 64Hz</option>
                      <option value={-7} id="128Hz">0.0078125s 128Hz</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Label for="ptpDelay_mechanism">
                    delay mechanism
                    </Label>
                    <Input type="select"
                          name="ptpDelay_mechanism"
                          id={"videoCard_"+videoCard.modelName+"_ptpDelay_mechanism"}
                          value={values.ptpDelay_mechanism}
                          disabled={!values.ptpEnable}
                          onChange={handleChange}>
                      <option value={"E2E"}>E2E</option>
                      <option value={"P2P"}>P2P</option>
                    </Input>
                </FormGroup>
              </Accordion>
              {/* <div className="header">
                  <div className="title secondary">
                    NMOS
                  </div>
                </div>
                 { ((config.nmosEnable!==undefined && config.nmosEnable !==values.nmosEnable) || (config.nmosEnable===undefined && !values.nmosEnable )) &&
                    <Alert color="warning">
                      Reboot necessary for the change to take effect
                    </Alert>
                  }
                <FormGroup check>
                  <Label check>
                    <Input id={"videoCard_"+videoCard.modelName+"_nmosEnable"}
                          type="checkbox"
                          name="nmosEnable"
                          onChange={handleChange}
                          checked={values.nmosEnable}
                          disabled={!licenseStatus}/>{' '}
                          Enable NMOS
                    </Label>
                </FormGroup> */}
         <FormGroup className="buttons">
            <Button id={"videoCard_"+videoCard.modelName+"_saveButton"}
                    color="primary"
                    disabled={isSubmitting || !dirty}
                    onClick={() => this.handleConfirm(true)}>
              <Translate id="genericLabel.SAVE.text"/>
            </Button>
          </FormGroup>
          { this.state.confirmModalOpened &&
          <RestartStreamhubModal onCancel={() => this.handleConfirm(false)}
                                  onConfirm={() => handleSubmit()}/>
          }
          </Form>
        )}}
      </Formik>
      </div>
    );
  }
}

VideoCardSMPTESettingsForm.propTypes = propTypes;


const mapStateToProps = (state) => {
    const {
      licenseModalOpened
    } = state.tools.system;
    return {
      licenseModalOpened,
      uploading: state.license.uploading,
      result: state.license.result
    };
  };


const mapDispatchToProps = (dispatch) => {
    return {
      callLicenseModal: (open) => dispatch(licenseModal(open)),
      callUploadLicense: (file) => dispatch(uploadLicenseSMPTE2110(file)) // temp
    };
  };
export default withLocalize(connect(mapStateToProps,mapDispatchToProps)(VideoCardSMPTESettingsForm));