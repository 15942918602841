import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {ClipLoader} from "react-spinners";
import {Button, Badge, Table} from "reactstrap";
import { connect } from 'react-redux';
import '../../../../../../node_modules/react-vis/dist/style.css';
import {SPINNER_DASHBOARD_COLOR} from "../../../../../constants";
import {formatBitrate, formatRTT} from "../../../../../utils/global-utils";
import {Translate} from "react-localize-redux";
import {inputPropTypes} from "../../../../../utils/models-prop-types";
import AWIcon from '@aviwest/ui-kit/dist/js/components/icon';

import {
  reconnectModem,
  disconnectModem
} from "../../dashboard.actions";

const propTypes = {
  input: inputPropTypes.isRequired,
  stats: PropTypes.shape({
    currentNetworkInterfaces: PropTypes.arrayOf(PropTypes.string),
    maxAudioLoss: PropTypes.number.isRequired,
    maxTotalBitrate: PropTypes.number,
    maxTotalRxBitrate: PropTypes.number,
    maxTotalTxBitrate: PropTypes.number,
    maxVideoLoss: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.shape({
      timestamp: PropTypes.number.isRequired,
      totalBitrate: PropTypes.number.isRequired,
      totalRxBitrate: PropTypes.number,
      totalTxBitrate: PropTypes.number,
      videoBitrate: PropTypes.number.isRequired,
      videoLostPackets: PropTypes.number.isRequired,
      audioBitrate: PropTypes.number.isRequired,
      audioLostPackets: PropTypes.number.isRequired,
    })).isRequired
  }),
  callReconnetModem: PropTypes.func.isRequired,
  callDisconnectModem: PropTypes.func.isRequired
};

class NetworkInfo extends Component {

  render(){
    if(!this.props.stats){
      return null;
    }
    const lastStat = this.props.stats.data.length > 0 ? this.props.stats.data[this.props.stats.data.length - 1] : null;
    return (
      <div className="network-info">
        <div className="interfaces">
          <Table borderless
                className="aw-table"
                size="sm">
            <thead>
            <tr>
              <th><Translate id="genericLabel.NETWORK.text"/></th>
              <th><Translate id="genericLabel.TYPE.text"/></th>
              <th><Translate id="genericLabel.ID.text"/></th>
              <th><Translate id="genericLabel.STATUS.text"/></th>
              <th><Translate id="genericLabel.LOST_PACKETS.text"/></th>
              <th><Translate id="genericLabel.BITRATE.text"/></th>
              <th><Translate id="genericLabel.RTT.text"/></th>
              <th><Translate id="genericLabel.ACTION.text"/></th>
            </tr>
            </thead>
            <tbody>
            { this.props.input.deviceNetworkInterfaces && this.props.input.deviceNetworkInterfaces.map(networkInterface => (
              <tr key={networkInterface.name}>
                <td>{ lastStat !== null && lastStat[networkInterface.name] ? <Badge color="info"><Translate id="genericLabel.ACTIVE.text"/></Badge> : null }</td>
                <td>
                  {networkInterface.type === 'ETHERNET' && networkInterface.sub_type !== 'BGAN' && <AWIcon name="network_interface_ethernet"/>}
                  {networkInterface.type === 'WIFI' && networkInterface.sub_type !== 'BGAN' && <AWIcon name="network_interface_wifi"/>}
                  {networkInterface.sub_type === 'BGAN' && <AWIcon name="network_interface_bgan"/>}
                  {networkInterface.type === 'GADGET' && <AWIcon name="usb"/>}
                  {networkInterface.type === 'WIFI_AP' && <AWIcon name="network_interface_wifi_ap"/>}
                  {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === 'UNKNOWN' && <AWIcon name="network_interface_cellular"/>}
                  {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '3G' && <AWIcon name="network_interface_3g"/>}
                  {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '4G' && <AWIcon name="network_interface_4g"/>}
                  {networkInterface.type === 'CELLULAR' && networkInterface.sub_type === '5G' && <AWIcon name="network_interface_5g"/>}
                  <span className='supsub-block'>
                    <sup>{ networkInterface.band }</sup>
                    <sub>{ networkInterface.band_aux }</sub>
                  </span>
                </td>
                <td><Translate id={`interfaceName.${networkInterface.name}.text`}/>{networkInterface.type === 'CELLULAR' && networkInterface.preset_auto === 0 && "*"}</td>
                <td>
                  { networkInterface.state === 'PENDING' &&
                    <ClipLoader loading={true} color={SPINNER_DASHBOARD_COLOR} size={18}/>
                  }
                  { networkInterface.state !== 'PENDING' && networkInterface.operator_name &&
                    <span>{ networkInterface.operator_name }</span>
                  }
                  { networkInterface.state !== 'PENDING' && !networkInterface.operator_name &&
                    <Translate id={`interfaceState.${networkInterface.error_state || networkInterface.state}.text`}/>
                  }
                </td>
                <td>{ lastStat !== null && lastStat[networkInterface.name] ? lastStat[networkInterface.name].lostPacketsCumul : null }</td>
                <td className="bitrate">{ lastStat !== null && lastStat[networkInterface.name] ? formatBitrate(lastStat[networkInterface.name].rxBitrate) : null }</td>
                <td style={{ minWidth: '4rem' }}>{ lastStat !== null && lastStat[networkInterface.name] ? formatRTT(lastStat[networkInterface.name].rtt) : null }</td>
                <td>
                { ((networkInterface.type === 'CELLULAR' && this.props.input.deviceProduct !== 'DMNG-APP') || networkInterface.caps === 1) &&
                  <Fragment>
                    { networkInterface.state === 'CONNECTED' &&
                      <Button className="basic" onClick={() => this.props.callDisconnectModem(networkInterface.name)}>
                        <AWIcon name="toggle_on" color="primary"/>
                      </Button>
                    }
                    { (networkInterface.state === 'DISCONNECTED' || networkInterface.state === 'INACTIVE' || networkInterface.state === 'UNKNOWN') &&
                      <Button className="basic" onClick={() => this.props.callReconnetModem(networkInterface.name)}>
                          <AWIcon name="toggle_off" color="primary"/>
                      </Button>
                    }
                    { networkInterface.state !== 'CONNECTED' && networkInterface.state !== 'DISCONNECTED' && networkInterface.state !== 'INACTIVE' && networkInterface.state !== 'UNKNOWN' &&
                      <Button className="basic" disabled>
                        <AWIcon name="toggle_off"/>
                      </Button>
                    }
                  </Fragment>
                }
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}

NetworkInfo.propTypes = propTypes;

const mapStateToProps = (state, ownProps) => {
  return {
    stats: state.datastore.inputStreamStats[ownProps.input.id]
  };
};
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    callReconnetModem: (name) => dispatch(reconnectModem(name, ownProps.input)),
    callDisconnectModem: (name) => dispatch(disconnectModem(name, ownProps.input)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NetworkInfo);