import React, { Component, Fragment } from 'react';
import { Translate } from "react-localize-redux";
import PropTypes from 'prop-types';

import { STATUS_OFF, STATUS_ON, SST, STATUS_ERROR, STATUS_WARNING, DASHBOARD_LAYOUT_FULLSIZE, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_GRID, VIDEO_IFB_STATUS_STARTED, IAP_STATUS_ON, STATUS_LIVE, LIVE_STATUS_CONNECTING, LIVE_STATUS_ON } from '../../../../../constants';
import Thumbnail from '../../../../common/thumbnail/index';
import { inputPropTypes, inputIpProfilePropTypes } from "../../../../../utils/models-prop-types";
import { AWControlGridItemContentTitle, AWControlGridItemThumbnail } from '@aviwest/ui-kit/dist/js/components/control-grid';
import AWIcon from "@aviwest/ui-kit/dist/js/components/icon";
import AWBadgeLabel from "@aviwest/ui-kit/dist/js/components/badge-label";
import { formatInputId } from "../../../../../utils/global-utils";
import BitrateBadge from './bitrate-badge';
import AWLoader from "@aviwest/ui-kit/dist/js/components/loader";

const propTypes = {
  input: inputPropTypes.isRequired,
  ipProfile: inputIpProfilePropTypes,
  layout: PropTypes.oneOf([DASHBOARD_LAYOUT_GRID, DASHBOARD_LAYOUT_INLINE, DASHBOARD_LAYOUT_FULLSIZE]),
};

class Preview extends Component {

  render() {
    const { input, ipProfile, layout, configInputProtocol } = this.props;
    const onMissingTranslation =  () => input.deviceMessage;
    let protocol = ipProfile ? ipProfile.protocol : SST;

    let errorWarningMessage =  null;
    if (input.status === STATUS_ERROR || input.status === STATUS_WARNING) {
      if (input.deviceErrorStatus < 0) {
        errorWarningMessage = <Translate id={`errorStatus.${input.deviceErrorStatus}.text`} options={ {onMissingTranslation} } data={ input.deviceErrorStatusParam }></Translate>
      } else {
        errorWarningMessage = <Translate id={`messageInfo.${input.deviceMessage}.text`} options={ {onMissingTranslation} }></Translate>
      }
    }

    let infoMessage = ((input.status === STATUS_ON || input.status === STATUS_LIVE) && (input.deviceMessage === 'Guest is prepared' || input.deviceMessage === 'Guest hung up')) ? <Translate id={`messageInfo.${input.deviceMessage}.text`} /> : null;
    const videoReturn = input.deviceVideoIFBStatus === VIDEO_IFB_STATUS_STARTED;
    const videoReturnProfile = input.deviceVideoReturnProfile > 0 ? true : false;
    const databridge = input.deviceIAPStatus === IAP_STATUS_ON;

    if (input.status === STATUS_OFF || (input.status === STATUS_ON && protocol !== SST)) {
      return (
        layout === DASHBOARD_LAYOUT_INLINE && input.status === STATUS_OFF ?
          (<Fragment>
            {formatInputId(input.id)}
            <AWControlGridItemContentTitle className='draggable'>
              {input.name}
            </AWControlGridItemContentTitle>
          </Fragment>) :
          (<AWControlGridItemContentTitle className='draggable'>
            <AWBadgeLabel disabled={configInputProtocol && configInputProtocol.enable ? false : true} left={formatInputId(input.id)}>{input.name}</AWBadgeLabel>
          </AWControlGridItemContentTitle>)
      )
    }

    return (
      <AWControlGridItemThumbnail className='draggable' overlay={input.inputInfo || input.outputInfo ? <div><p>&nbsp;{input.inputInfo}</p><p>{input.outputInfo}</p></div> : null}
        error={input.status === STATUS_ERROR ? errorWarningMessage : null}
        warning={input.status === STATUS_WARNING ? errorWarningMessage : null}>
        <Thumbnail inputId={input.id} timestamp={new Date().getTime()}>
          { !errorWarningMessage && (input.deviceLiveStatus === LIVE_STATUS_CONNECTING || (input.deviceLiveStatus === LIVE_STATUS_ON && input.status !== STATUS_LIVE)) &&
          <AWLoader active={true} position="centered"/>
          }
          <AWControlGridItemContentTitle>
            { !errorWarningMessage && layout === DASHBOARD_LAYOUT_GRID && <BitrateBadge inputId={input.id} />}
            { infoMessage && <AWBadgeLabel fill>{infoMessage}</AWBadgeLabel> }
            <br />
            <AWBadgeLabel left={formatInputId(input.id)}
              right={videoReturnProfile || videoReturn || databridge ? <Fragment>
                {videoReturnProfile && <AWIcon name="video_return_profile" />}
                {videoReturn && <AWIcon name="video_return" />}
                {databridge && <AWIcon name="data_bridge" />}
              </Fragment> : null}>
              {input.name}
            </AWBadgeLabel>
          </AWControlGridItemContentTitle>
        </Thumbnail>
      </AWControlGridItemThumbnail>
    );
  }
}

Preview.propTypes = propTypes;

export default Preview;