import {Formik} from "formik";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {Button, Col, Form, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {Translate, withLocalize} from "react-localize-redux";

import {outputSafestreamsURI} from "../../../../misc/streamhub.adapters";
import {isEmptyString, isGroupValid} from "../../../../utils/string-utils";
import {SST} from "../../../../constants";
import HelpLayout from "../../../common/help-layout";
import PasswordRevealInput from "../../../common/password-reveal-input";

const propTypes = {
  additionalActions: PropTypes.node,
  config: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    ipAddress: PropTypes.string.isRequired,
    port: PropTypes.number.isRequired,
    destChannel: PropTypes.string.isRequired,
    delay: PropTypes.number,
    login: PropTypes.string,
    password: PropTypes.string
  }),
  forbiddenNames: PropTypes.arrayOf(PropTypes.string).isRequired,
  forbiddenUris: PropTypes.arrayOf(PropTypes.object).isRequired,
  destinationInputs: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  localIps: PropTypes.arrayOf(PropTypes.string),
  alert: PropTypes.node,
  protocol: PropTypes.node
};

const LATENCY_MIN = 300
const LATENCY_MAX = 5000

const SAFESTREAMForm = (props) => {
  const { config, destinationInputs, forbiddenNames, forbiddenUris, translate, alert, protocol } = props;
  const [passwordSpecific, setPasswordSpecific] = useState(config && config.password ? true : false)

  const uriList = (values) => {
    return [outputSafestreamsURI(values)];
  };

  const handleSubmit = (values) => {

    props.onSubmit(values);
  };

  const handleValidation = (values) => {
    const errors = {};

    // Name
    if(isEmptyString(values.name)){
      errors.name = 'genericLabel.REQUIRED_FIELD.text';
    }
    else if(values.name.length >= 32){
      errors.name = 'genericLabel.TOO_LONG.text';
    }
    else if(forbiddenNames.indexOf(values.name) !== -1){
      errors.name = 'genericLabel.DUPLICATED_VALUES.text';
    }
    else if(!isGroupValid(values.name)){
      errors.name = 'genericLabel.INVALID_FORMAT.text'
    }

    // IP Address
    if(isEmptyString(values.ipAddress)){
      errors.ipAddress = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Port
    if(isEmptyString(values.port)){
      errors.port = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Destination channels
    if(isEmptyString(values.destChannel)){
      errors.destChannel = 'genericLabel.REQUIRED_FIELD.text';
    }

    // Latency channels
    if (isEmptyString(values.delay)) {
      errors.delay = 'genericLabel.REQUIRED_FIELD.text';
    } else if (values.delay < LATENCY_MIN || values.delay > LATENCY_MAX) {
      errors.delay = 'genericLabel.INVALID_FORMAT.text';
    }

    if (isEmptyString(values.login) && !isEmptyString(values.password)) {
      errors.login = 'genericLabel.REQUIRED_FIELD.text';
    }

    if (!isEmptyString(values.login) && isEmptyString(values.password)) {
      errors.password = 'genericLabel.REQUIRED_FIELD.text';
    } else {
      delete errors.password;
    }

    // Password with length > 32 characters are not encrypted (Bugs #15905)
    if((!config || (config && config.password !== values.password)) && values.password.length > 32) {
      errors.password = 'genericLabel.TOO_LONG.text';
    }

    const uris = uriList(values);
    if(uris !== null && uris.find(uri => forbiddenUris.filter( element => element.uri === uri).length !== 0) !== undefined){
      errors.duplicateUriFrom = forbiddenUris.find(element => uris.filter(uri => uri === element.uri).length !== 0).name
      errors.ipAddress = 'genericLabel.DUPLICATED_VALUES_FROM.text';
      errors.port = 'genericLabel.DUPLICATED_VALUES_FROM.text';
      errors.destChannel = 'genericLabel.DUPLICATED_VALUES_FROM.text';
    }
    return errors;
  };

  return (
    <Formik initialValues={{
              id: config ? config.id : undefined,
              mode: SST,
              name: config ? config.name : '',
              ipAddress: config ? config.ipAddress : '',
              port: config ? config.port : '',
              destChannel: config ? config.destChannel : '',
              delay: config && config.delay ? config.delay : 1000,
              login: config && config.login ? config.login : '',
              password: config && config.password ? config.password : ''
            }}
            validate={ handleValidation }
            validateOnBlur={false}
            validateOnChange={true}
            onSubmit={ handleSubmit }>
      {({
          values,
          errors,
          dirty,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
          /* and other goodies */
        }) => (
        <Form onSubmit={ handleSubmit }>

          <HelpLayout
          filename={`c_sh_sst_output_profile.html`}
          form={<fieldset disabled={config && config.enable === true}>
            {alert}
            {protocol}

            <FormGroup>
              <Label for="name">
                <Translate id="genericLabel.NAME.text"/>
              </Label>
              <Input type="text"
                    name="name"
                    id="outputsProfile_sst_name"
                    invalid={errors.name !== undefined}
                    placeholder={ translate('genericLabel.NAME.text') }
                    value={values.name}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.name} />
              </FormFeedback>
            </FormGroup>

            <FormGroup>
              <Label>
                <Translate id="genericLabel.DESTINATION.text" />
              </Label>
              <Row className="destination-row"
                  form>
                <Col xs={6}>
                  <FormGroup>
                    <Input type="text"
                          name="ipAddress"
                          id="outputsProfile_sst_ipAddress"
                          invalid={errors.ipAddress !== undefined}
                          onChange={handleChange}
                          placeholder={ translate('genericLabel.HOST.text') }
                          value={values.ipAddress}/>
                    <FormFeedback>
                      <Translate id={errors.ipAddress} /> { errors.duplicateUriFrom }
                    </FormFeedback>
                  </FormGroup>
                </Col>
                <Col xs={4}>
                  <FormGroup>
                    <Input type="number"
                          name="port"
                          id="outputsProfile_sst_port"
                          invalid={errors.port !== undefined}
                          onChange={handleChange}
                          placeholder={ translate('genericLabel.PORT.text') }
                          value={values.port}/>
                    <FormFeedback>
                      <Translate id={errors.port} /> { errors.duplicateUriFrom }
                    </FormFeedback>
                  </FormGroup>
                </Col>
              </Row>
            </FormGroup>
            <FormGroup>
              <Label for="destChannel">
                <Translate id="genericLabel.DESTINATION_CHANNEL.text" />
              </Label>
              <Input type="select"
                    name="destChannel"
                    id="outputsProfile_sst_destChannel"
                    invalid={ errors.destChannel !== undefined }
                    onChange={handleChange}
                    value={values.destChannel}>
                <option value=""> - </option>
                { destinationInputs.map(dest => {
                  return <option key={dest} value={dest}>{dest}</option>
                })}
              </Input>
              <FormFeedback>
                <Translate id={errors.destChannel} /> { errors.duplicateUriFrom }
              </FormFeedback>
            </FormGroup>
            <FormGroup>
              <Label for="delay">
                <Translate id="genericLabel.LATENCY.text"/>
              </Label>
              <Input type="number"
                    name="delay"
                    id="outputsProfile_sst_delay"
                    min={LATENCY_MIN}
                    max={LATENCY_MAX}
                    invalid={errors.delay !== undefined}
                    placeholder={ translate('genericLabel.LATENCY.text') }
                    value={values.delay}
                    onChange={handleChange}/>
              <FormFeedback>
                <Translate id={errors.delay} />
              </FormFeedback>
              <div className="indicator">
                <Translate id="genericLabel.LATENCY_MS.text"/>
              </div>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Input type="checkbox"
                      name="passwordSpecific"
                      id="outputsProfile_sst_passwordSpecific"
                      onChange={() => {
                        setPasswordSpecific(!passwordSpecific);
                        setFieldValue('login', '');
                        setFieldValue('password', '');
                      }}
                      checked={passwordSpecific}/>{' '}
                <Translate id="genericLabel.PASSWORD_SPECIFIC.text"/>
              </Label>
            </FormGroup>
            { passwordSpecific &&
            <>
              <FormGroup>
                <Label for="login">
                  <Translate id="genericLabel.LOGIN.text"/>
                </Label>
                <Input type="text"
                      name="login"
                      id="outputsProfile_sst_login"
                      invalid={errors.login !== undefined}
                      placeholder={ translate('genericLabel.LOGIN.text') }
                      value={values.login}
                      onBlur={handleBlur}
                      onChange={handleChange}/>
                <FormFeedback>
                  <Translate id={errors.login} />
                </FormFeedback>
              </FormGroup>
              <FormGroup>
                <Label for="password">
                  <Translate id="genericLabel.PASSWORD.text"/>
                </Label>
                <PasswordRevealInput name="password"
                                    id="outputsProfile_sst_password"
                                    setFieldValue={setFieldValue}
                                    invalid={errors.password !== undefined}
                                    placeholder={ translate('genericLabel.PASSWORD.text') }
                                    value={values.password}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    error={errors.password}/>
              </FormGroup>
            </>
            }


            <div className="uri-preview">
              { uriList(values).map((uri, index) => (<div key={`${uri}-${index}`} className="uri" id={"outputsProfile_sst_uri_"+index}>{uri}</div>)) }
            </div>
            </fieldset>}

            buttons={<FormGroup className="buttons">
              { props.additionalActions }
              <Button id="outputsProfile_sst_saveButton"
                      color="primary"
                      disabled={!dirty}
                      type="submit">
                <Translate id="genericLabel.SAVE.text"/>
              </Button>
            </FormGroup>
          } />
        </Form>
      )}
    </Formik>
  );
};

SAFESTREAMForm.propTypes = propTypes;

export default withLocalize(SAFESTREAMForm);
